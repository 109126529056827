<template>
	<div>
		<LoadingSpinner v-if="processing" />
		<template v-else>
            <template v-if="!dps.length">
                {{ $t('gynecologie.aucune_donnee_dps_saillie_ws') }}
           	</template>
	        <div class="box my-3" v-for="data in dps" v-bind:key="data.monteSaillie">
	            {{ $t('horse.etalon') }} : <b>{{ data.etalonNom }}</b>
	            <br>
	            {{ $t('monte.porteuse') }} : <b>{{ data.porteuseNom }}</b>
	            <br>
	            {{ $t('monte.date_premier_saut') }} : <b>{{ data.monteDatePremierSaut_format }}</b>
	            <br>
	            {{ $t('compte.datenaissance') }} : <b>{{ data.produitDateNaiss_format }}</b>
	            <br>
	            {{ $t('horse.nom_naissance') }} : <b>{{ data.produitNom }}</b>
	            <br>
	            {{ $t('horse.nom_retenue') }} : <b>{{ data.nomRetenu }}</b>

	            <e-select
	                v-model="data.acte_type"
	                id="actestype_id"
	                track-by="actestype_id"
	                label="actestype_label"
	                :placeholder="$t('acte.selectionnez_acte')"
	                :selectedLabel="$t('global.selected_label')"
	                :options="actes_types"
	                :searchable="true"
	                :allow-empty="false"
	                :show-labels="false"
	                :group-select="false"
	            >
	                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	            </e-select>

	            <div class="text-center mt-3">
	                <b-form-checkbox v-model="data.selected"></b-form-checkbox>
	            </div>
	        </div>

	        <div v-if="dps.length > 0" class="text-center mt-3">
	            <b-button variant="primary" :disabled="loading_add_dps" @click="add_dps()">
	                <font-awesome-icon v-if="loading_add_dps" :icon="['fas', 'spinner']" pulse />
	                {{ $t('global.ajouter') }}
	            </b-button>
	        </div>
	    </template>
	</div>
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import GynecologieMixin from "@/mixins/Gynecologie.js"
    import ActeTypeMixin from "@/mixins/ActeType.js"
    import Common from '@/assets/js/common'
    import _groupBy from 'lodash/groupBy'

	export default {
		name: 'AskDPS',
        mixins: [Shutter, ShutterTriggers, GynecologieMixin, ActeTypeMixin],
		props: {
			horses_ids: { type: Array, default: () => [] },
			shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] },
		},
		data () {
			return {
				actes_types: null,
				dps: [],
				processing: false,
				loading_add_dps: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.processing = true
				this.actes_types = await this.getActeTypeSaillie()
				const seasons = await this.loadSeasons()

				if(seasons.length == 0) {
					this.goToNext()
					return false
				}

				const all_dps = []
				await Common.asyncForEach(seasons, async (season) => {
					await Common.asyncForEach(this.horses_ids, async (horse_id) => {
		                let dps = await this.getInfoSailliesWs(parseInt(horse_id), season.season_id)
		                for(let i = 0; i < dps.length; i++) {
		                    dps[i].selected = false
		                    dps[i].acte_type = this.actes_types[0]
		                    dps[i].horse = parseInt(horse_id)
		                    all_dps.push(dps[i]);
		                }
		            })
				})
                this.dps = all_dps

                this.processing = false
			},

			async add_dps() {
				this.loading_add_dps = true;

                const checked = this.dps
                    .filter(_dps => _dps.selected);

                const checked_group = _groupBy(checked, 'horse')

                for(let horse in checked_group) {
                	let data = checked_group[horse].map(saillie => {
                		return {
                			monteSaillie: saillie.monteSaillie,
                        	monteAnnee: saillie.monteAnnee,
                        	monteActeType: saillie.acte_type.actestype_id
                        }
                	})
                	await this.AddInfoSailliesWs(horse, data)
                }

                this.successToast()
                this.goToNext()

                this.loading_add_dps = false;
			},

			goToNext() {
				if(this.next.length == 0) {
					this.$router.push({ name: 'HorseFicheActes', params: {horse_id: this.horses_ids[0].horse_id} })
					return false
				}
                const triggered = this.next[0]
                this.next.shift()
                this.shutterPanel().close('pension')
                this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
            },
		},

		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>